import React from "react"
import { graphql } from "gatsby"
import Termine from "../templates/termine.jsx"

export default ({ data, location }) => (
  <Termine
    data={data}
    location={location}
    title="Alle Termine"
    filter={a => true}
  />
)

export const query = graphql`
  query allApps {
    ...AllAppointments
  }
`
